import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import design from './home/brooke-cagle-NoRsyXmHGpI-unsplash-1.png'
import { Link } from 'react-router-dom'
import { FaPlane } from 'react-icons/fa6'



export default function Webdesign() {

    return (
        <Container className='back-webdesign pt-5 pb-5' fluid>
            <Row className='justify-content-center text-leif  fs-5   overflow'>
                <Col lg={6}> <img src={design} className='img-fluid ' data-aos="zoom-out-up" data-aos-duration="1000" alt='not'></img></Col>
                <Col lg={6} className='mt-5' data-aos="fade-left" data-aos-duration="1000">
                    <Row>
                        <h1 className='fs-3 fw-semibold   white-con'>We Design,
                        </h1>
                    </Row>
                    <Row >  <h1 className='fs-1 fw-semibold   white-con '>Builds Brand &
                        <br />
                        Digital Project <span className='contact-color'>Designs</span></h1>
                        <p className='mb-5 pb-4 pt-3'>
                            We are a leading web design and development company, dedicated to providing businesses of all sizes with high-quality, custom-built websites that help them succeed online. </p></Row>
                    <Link to={'/contact'} className='get-contact mt-5'>Get A Quote <FaPlane /></Link>

                </Col>
            </Row>
        </Container>
    )
}
